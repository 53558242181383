<template>
  <div class="global-settings">
    <b-container>
      <b-row>
        <b-col lg="10">
          <div class="inner">
            <b-card title="اضافة مسؤل التامين">
              <div class="d-flex align-items-center gap_3">
                <input-form
                  placeholder="أدخل البريد الالكترونى"
                  label="أدخل البريد الالكترونى"
                  v-model="admin.email"
                  name="admin_email"
                  validate="required"
                  class="d-flex align-items-center gap_1 flex-fill email-inp"
                />
                <main-select
                  labelTitle="اختر المدينة"
                  placeholder="اختر المدينة"
                  label="تحديد الدور"
                  :dir="'rtl'"
                  :options="adminPermissions"
                  :reduce="admin => admin.value"
                  v-model="admin.permissions"
                />
                <b-button variant="warning" type="submit" class="global-settings-btn">حفظ</b-button>
              </div>
            </b-card>

            <b-card title="كل المسئولين">
              <main-table :fields="fields" list_url="merchant/orders"></main-table>
            </b-card>
          </div>
          <div class="inner mt-5">
            <b-card title="الادوار" class="titles-card">
              <b-card title="مسؤول">
                <b-card-text>جميع المحتويات في هذا الموقع ، بما في ذلك النصوص الكاملة للوثائق، والتصميم والصور والبرامج والنصوص وغيرها من المعلومات (إجمالا ، جميع المحتويات في هذا الموقع ، بما في ذلك النصوص الكاملة للوثائق، والتصميم والصور والبرامج والنصوص وغيرها من المعلومات (إجمالا ، جميع المحتويات في هذا الموقع ، بما في ذلك النصوص الكاملة للوثائق، والتصميم والصور والبرامج والنصوص وغيرها من المعلومات (إجمالا ،
                </b-card-text>
              </b-card>
              <b-card title="مدير">
                <b-card-text>جميع المحتويات في هذا الموقع ، بما في ذلك النصوص الكاملة للوثائق، والتصميم والصور والبرامج والنصوص وغيرها من المعلومات (إجمالا ، جميع المحتويات في هذا الموقع ، بما في ذلك النصوص الكاملة للوثائق، والتصميم والصور والبرامج والنصوص وغيرها من المعلومات (إجمالا ، جميع المحتويات في هذا الموقع ، بما في ذلك النصوص الكاملة للوثائق، والتصميم والصور والبرامج والنصوص وغيرها من المعلومات (إجمالا ،
                </b-card-text>
              </b-card>
              <b-card title="محرر">
                <b-card-text>جميع المحتويات في هذا الموقع ، بما في ذلك النصوص الكاملة للوثائق، والتصميم والصور والبرامج والنصوص وغيرها من المعلومات (إجمالا ، جميع المحتويات في هذا الموقع ، بما في ذلك النصوص الكاملة للوثائق، والتصميم والصور والبرامج والنصوص وغيرها من المعلومات (إجمالا ، جميع المحتويات في هذا الموقع ، بما في ذلك النصوص الكاملة للوثائق، والتصميم والصور والبرامج والنصوص وغيرها من المعلومات (إجمالا ،
                </b-card-text>
              </b-card>
              <b-card title="محلل بيانات">
                <b-card-text>جميع المحتويات في هذا الموقع ، بما في ذلك النصوص الكاملة للوثائق، والتصميم والصور والبرامج والنصوص وغيرها من المعلومات (إجمالا ، جميع المحتويات في هذا الموقع ، بما في ذلك النصوص الكاملة للوثائق، والتصميم والصور والبرامج والنصوص وغيرها من المعلومات (إجمالا ، جميع المحتويات في هذا الموقع ، بما في ذلك النصوص الكاملة للوثائق، والتصميم والصور والبرامج والنصوص وغيرها من المعلومات (إجمالا ،
                </b-card-text>
              </b-card>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data () {
    return {
      adminPermissions: [
        { name: 'مسئول', value: 0 },
        { name: 'مسئول2', value: 1 }
      ],
      admin: {
        email: '',
        permissions: ''
      }
    }
  }
}
</script>

<style lang="scss">
.global-settings {
  .card-title {
    border-bottom: 1px solid #DEDEDE;
    padding-bottom: 15px !important;
    margin-bottom: 20px !important;
  }
  .titles-card {
    > .card-body > .card-title {
      border-bottom: 1px solid #DEDEDE;
      padding-bottom: 15px !important;
      margin-bottom: 20px !important;
    }
    .card:not(:last-child) {
      border-bottom: 1px solid #DEDEDE;
      padding-bottom: 15px !important;
      margin-bottom: 20px !important;
    }
    .card-title {
      border-bottom: none;
      padding-bottom: 0 !important;
      margin-bottom: 9px !important;
      color:  #646464;
      font-size: 20px;
      font-weight: bold;
    }
    .card-text {
      color: #646464;
      font-size: 16px;
    }
  }
  .email-inp {
    label {
      margin-bottom: 0 !important;
    }
    input {
      min-width: 300px;
    }
  }
  .global-settings-btn {
    border: none !important;
    border-radius: 8px;
    background: #D39D451A !important;
    color: #D39D45 !important;
    width: 98px;
    height: 50px;
    font-size: 18px !important;
  }
}
</style>
